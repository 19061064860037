import React, { useState, useEffect, useContext } from 'react'
import OpcionMenuLateral from '../OpcionMenuLateral/OpcionMenuLateral.container'
import sessionContext from '../../../../context/session/sessionContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * 
 * Menu lateral para emplear en las deiferentes paginas.
 * @param {Object} content objeto con los enlaces, iconos y textos de las diferentes secciones 
 * @param {Funcrion} setActiveSection funcion para modificar el state del componente padre con la seccion actual
 * @param {string} activeSection la secion activa 
 */

const MenuLateral = ({ isCollapsed, changeIsCollapse, className, content}) => {

    //State para identificar la opcion del menu lateral activa. Por defecto la primera de las opciones en el content
    //const [activeOption, setactiveOption] = useState(content[0].sectionId)

    const { isLoading, activeOption, setActiveOption } = useContext(sessionContext)

    
    return (
        <div className={className} >
           
                
               <div className="pt-5 menu-lateral" >

               <FontAwesomeIcon icon={isCollapsed ? "angle-right" : "angle-left"} className="collapse-icon clickable" size="2x"
                    color="#2a2b33"
                    style={{left: isCollapsed? '53%' : '86%'}}
                    onClick={changeIsCollapse}
                />
                    {
                        content.length > 0

                        ? 
                       content.map((opcion, index)=>(
                           <OpcionMenuLateral key={index} 
                           content={opcion} 
                           activeOption={activeOption} 
                           setactiveOption={setActiveOption}
                           isCollapsed={isCollapsed}
                           />
                       ))
                        :
                        null
                    }
                
                    </div>



        </div>
    )
}


export default MenuLateral


