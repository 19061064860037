import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clientesContext from '../../../../../context/clientes/clientesContext'
import FacturasCliente from './FacturasCliente/FacturasCliente.container'
import FormularioDetallesCliente from './FormularioDetallesCliente/FormularioDetallesCliente.container'
import EditorFacturas from './EditorFacturas/EditorFacturas.container'

const DetalleCliente = ({ className }) => {

  const { selectedCliente } = useContext(clientesContext)

  //estado para definir si se está editando una factura

  const [editingFactura, seteditingFactura] = useState(false)
  useEffect(() => {
    seteditingFactura(false)
  }, [selectedCliente])


  return (
    <div className={className} >
      {selectedCliente
        ?

        <>
          <div className="col-3">
            <FormularioDetallesCliente cliente={selectedCliente} />
          </div>
          <div className="col-9">

            {
              editingFactura

                ?
                <EditorFacturas
                  cliente={selectedCliente}
                  style={{ backgroundColor: "white" }}
                  className="w-100 pr-4"
                  seteditingFactura={seteditingFactura}
                />

                :

                <FacturasCliente
                  className="w-100 pr-4"
                  cliente={selectedCliente}
                  seteditingFactura={seteditingFactura}
                />

            }
          </div>
        </>

        :
        <i>Seleccione un cliente</i>
      }
    </div>
  )
}


export default DetalleCliente
