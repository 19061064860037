import React, { useState, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import firebase, { FirebaseContext } from '../../../../firebase'

import { useHistory } from "react-router-dom";
import Spinner from '../../Spinner/Spinner.container'
//validaciones
import useValidacion from '../../../../hooks/useValidacion'
import sessionContext from '../../../../context/session/sessionContext'
import validarIniciarSesion from './validarIniciarSesion'
import { Router } from 'react-router-dom'

import { ResponsiveCardBody, ResponsiveImage } from './InicioSesion.styled'
const STATE_INICIAL = {
    email: "",
    password: ""
}

const InicioSesion = ({ className }) => {

    let history = useHistory();

    const { usuario } = useContext(FirebaseContext)
    const { setLoadingSection, isLoadingSection } = useContext(sessionContext)

    if (usuario !== null) {
        setLoadingSection()
        history.push('/inicio')
    }


    const { valores, errores, submitForm, handleSubmit, handleChange } = useValidacion(STATE_INICIAL, validarIniciarSesion, iniciarSesion)

    const [error, setError] = useState(null)

    const { email, password } = valores

    async function iniciarSesion() {
        try {
            await firebase.login(email, password)
            setLoadingSection()
            history.push('/gestionWeb') //FIXME: Mandar a la pantalla principal

        } catch (error) {
            console.error("Hubo un error al autentificar el usuario ", error.message)
            setError("Contraseña o email incorrecto")
        }
    }

    return (
        <>
            {
                isLoadingSection

                    ?
                    <div style={{ marginTop: "20%" }}>
                        <Spinner />
                    </div>

                    :
                    <div className={className} >
                        <div className="container login">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-5 mx-auto">
                                    <div className="card shadow">
                                        <ResponsiveImage src={process.env.PUBLIC_URL + '/SALVER_LOGO.png'}
                                            width="300" className="card-image-top logo" alt="Salver logo" />
                                        <ResponsiveCardBody className="card-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="password">Contraseña</label>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                {
                                                    error
                                                        ?
                                                        <div className="alert alert-danger">
                                                            {error}
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <input type="submit" className="btn btn-primary btn-block" value="Iniciar sesión" />
                                            </form>
                                        </ResponsiveCardBody>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

            }
        </>

    )
}


export default InicioSesion
