import React, { useContext, useState, useEffect } from 'react'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import gestionWebContext from '../../../../../context/gestionWeb/gestionWebContext'
import moment from 'moment'
import sessionContext from '../../../../../context/session/sessionContext'
import { FirebaseContext } from '../../../../../firebase'
import SavingIcon from '../../../SavingIcon/SavingIcon.container'
import MarkdownIt from 'markdown-it'
import Turndown from "turndown";
import MdEditor from 'react-markdown-editor-lite'
import { NewAnuncioButton, NewAnuncioCancelButton } from './ControlAnuncio.styled'
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/**
 * 
 * Componente que renderiza las opciones de edicion del anuncio seleccionado
 * Carga una tabla con los anuncios activos y un editor de contenido
 * El contenido, almacenado en firestore en la coleccion anunciosActivos alamcena el mensaje en HTML
 * Al cargarlo se renderiza en el editor pasandolo a markdown y al gurdarlo se vuelve a rendereizar a html
 */
const ControlAnuncio = ({ className, sethasLoad }) => {

  const { selectedAnuncio, cargarAnunciosActivos, selectAnuncio, setSelectedAnuncio } = useContext(gestionWebContext)
  const { modifyLoadingOption } = useContext(sessionContext)
  //estados para carga y confirmacion de la operacion de guardado 
  const [isSaving, setIsSaving] = useState(false)
  const [docSaved, setdocSaved] = useState(false)
  //Estado para almacenar los cambios en el anuncio
  const [changes, setchanges] = useState({
    isChanged: false,
    fechaExpiracion: null,
    mensaje: null
  })

  const [newAnuncio, setnewAnuncio] = useState(null)

  //context de firebase
  const { usuario, firebase } = useContext(FirebaseContext)


  //Carga de anuncios al cargar el componente
  useEffect(() => {

    const obtenerAnuncios = async () => {

      await firebase.db.collection("anunciosActivos").orderBy("timestamp", "desc")
        .onSnapshot(handleSnapshot);

    }
    modifyLoadingOption(true)
    obtenerAnuncios()
    modifyLoadingOption(false)
    setdocSaved(false)
    //FIXME: Optimizar renderizado del componente para que no recargue la petición en cada renderizado
  }, [])


  useEffect(() => {

    setdocSaved(false)


  }, [selectedAnuncio])

  //funcion para procesar el resultadod e la consulta a firestore
  function handleSnapshot(snapshot) {

    const anuncios = snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      }
    })
    console.log(anuncios)
    //set anuncios activos en el state
    cargarAnunciosActivos(anuncios)
    sethasLoad(true)

  }

  const generateStringDate = (date) => {

    date = moment(date, "DD-MM-YYYY").toDate()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()


    const str_day = day < 10 ? "0" + day : day
    const str_month = month < 10 ? "0" + month : month


    return `${year}-${str_month}-${str_day}`


  }

  // Initialize a markdown parser
  const mdParser = new MarkdownIt(/* Markdown-it options */);
  //Initialize turndown parser (HTML to Markdown)
  const td = new Turndown();
  //Funcion para prcesar los cambios en el editor de markdown
  function handleEditorChange({ html, text }) {

    if (html === selectedAnuncio.mensaje) {
      setchanges({
        ...changes,
        mensaje: null
      })
    }
    else {
      setchanges({
        ...changes,
        isChanged: true,
        mensaje: html
      })
    }


  }

  //Funcion para procesar cambios en la fecha
  function handleDateInputChange(e) {

    console.log(e.target.value)

    if (moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY') === selectedAnuncio.fechaExpiracion) {
      setchanges({
        ...changes,
        isChanged: true,
        fechaExpiracion: null
      })
    }
    else {
      setchanges({
        ...changes,
        isChanged: true,
        fechaExpiracion: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      })
    }

  }

  /**
   * Funcion para procesar el guardado de datos 
   * 
   * Obtiene el documento editado, lo modifica con los cambios almacenados en el estado y lo 
   * actualiza en la base de datos 
   */

  const handleSave = async () => {

    setIsSaving(true)
    const updatedanuncio = {
      fechaPublicacion: selectedAnuncio.fechaPublicacion,
      fechaExpiracion: changes.fechaExpiracion !== null ? changes.fechaExpiracion : selectedAnuncio.fechaExpiracion,
      mensaje: changes.mensaje !== null ? changes.mensaje : selectedAnuncio.mensaje,
    }

    //Obtener el documento anuncio por id y lo actualiza
    const updated = await firebase.db.collection("anunciosActivos").doc(selectedAnuncio.id).update(updatedanuncio)

    //Actualizamos los anuncios en el state 

    const obtenerAnuncios = async () => {

      await firebase.db.collection("anunciosActivos").orderBy("timestamp", "desc").onSnapshot(handleSnapshot);

    }
    //funcion para procesar el resultadod e la consulta a firestore
    function handleSnapshot(snapshot) {

      const anuncios = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      console.log(anuncios)
      //set anuncios activos en el state
      cargarAnunciosActivos(anuncios)


    }


    obtenerAnuncios()
    //actualizamos el anuncio selecionado con el nuevo anuncio modificado
    selectAnuncio(selectedAnuncio.id)
    setchanges({
      isChanged: false,
      fechaExpiracion: null,
      mensaje: null
    })
    setIsSaving(false)
    setdocSaved(true)
  }
  //Funcion para iniciar la creacion de un nuevo annuncio
  const createNewAnuncio = () => {

    //1. DEselecciona el anuncio seleccionado
    selectAnuncio(null)
    //2. Inicializa el estado de nuevo anuncio al objeto vacio
    setnewAnuncio({
      fechaPublicacion: null,
      fechaExpiracion: null,
      mensaje: ""
    })

  }

  //funcion para guardar el nuevo anuncio
  const handleSaveNewAnuncio = async () => {

    console.log(newAnuncio)
    //añadimos al nuevo anuncio un timestamp
    let anuncio = newAnuncio
    anuncio.timestamp = new Date()
    //Iniciar estado de guardado para icono
    setIsSaving(true);
    //almacenar en base de datos
    await firebase.db.collection("anunciosActivos").add(anuncio)

    async function obtenerAnuncios() {

      await firebase.db.collection("anunciosActivos").orderBy("timestamp", "desc").onSnapshot(handleSnapshot)


    }
    //funcion para procesar el resultadod e la consulta a firestore
    function handleSnapshot(snapshot) {

      const anuncios = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      console.log(anuncios)
      //set anuncios activos en el state
      cargarAnunciosActivos(anuncios)


    }

    //Establecer nuevo anuncio a null
    setnewAnuncio(null)

    //Terminar estado de guardado
    setIsSaving(false)
    //Establecer estado de documento guardado
    setdocSaved(true)

    //recargar anuncios
    obtenerAnuncios()


  }

  function handleEditorChangeNewAnuncio({ html, text }) {


    setnewAnuncio({
      ...newAnuncio,
      mensaje: html
    })



  }
  function handleDateInputChangeNewAnuncio(e) {

    setnewAnuncio({
      ...newAnuncio,

      [e.target.name]: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
    })


  }

  return (
    <div className={className} >
      {
        selectedAnuncio !== undefined && selectedAnuncio !== null && newAnuncio === null

          ?

          <>
            <h3 className="mb-xl-4">Anuncio ID: {selectedAnuncio.id.substring(0, 5)}</h3>
            <div className="row mb-xl-3">
              <div className="col-12">

                <div className="col-3 pl-0 d-inline">
                  <label htmlFor="fecha_expiracion" className=" form-label">Fecha de expiración:</label>
                </div>
                <div className=" col-6 d-inline ">
                  <input
                    onChange={(e) => handleDateInputChange(e)}
                    min={generateStringDate(selectedAnuncio.fechaExpiracion)}
                    value={changes.fechaExpiracion == null ? generateStringDate(selectedAnuncio.fechaExpiracion) : generateStringDate(changes.fechaExpiracion)}
                    type="date"
                    id="fecha_expiracion"
                    name="fechaExpiracion" />
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-12">

                <MdEditor
                  value={td.turndown(selectedAnuncio.mensaje)}
                  style={window.innerHeight > 1024 ? { height: "500px" } : { height: "400px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={handleEditorChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <div>
                  <SavingIcon className="saving-icon"
                    isSaving={isSaving}
                    saved={docSaved}
                  />
                  <button className="btn btn-lg btn-success mt-2 " onClick={() => handleSave()} disabled={changes.mensaje == null && changes.fechaExpiracion == null ? true : false}>
                    Guardar cambios
                 </button>

                </div>
              </div>
            </div>
          </>
          :
          null
      }
      {//Logica para mostrar el formulario de fecha y contenido vacios en la creacion de un nuevo anuncio
        newAnuncio !== null

          ?

          <>
            <h3 className="mb-xl-4">Creación de nuevo anuncio</h3><NewAnuncioCancelButton className="btn btn-lg btn-danger " onClick={() => setnewAnuncio(null)}>
              Cancelar x
                 </NewAnuncioCancelButton>
            <div className="row mb-xl-3 row-dates-selector">

              <label htmlFor="fecha_expiracion" className=" form-label">Fecha de publicación:</label>
              <div className=" col-6 d-inline ">
                <input
                  onChange={(e) => handleDateInputChangeNewAnuncio(e)}
                  min={new Date().toDateString()}
                  value={newAnuncio.fechaPublicacion == null ? (new Date().toDateString()) : generateStringDate(newAnuncio.fechaPublicacion)}
                  type="date"

                  id="fecha_publicacion"
                  name="fechaPublicacion" />
              </div>

              <label htmlFor="fecha_publicacion" className=" form-label">Fecha de expiración:</label>
              <div className=" col-6 d-inline ">
                <input
                  onChange={(e) => handleDateInputChangeNewAnuncio(e)}
                  min={new Date().toDateString()}
                  value={newAnuncio.fechaExpiracion == null ? new Date().toDateString() : generateStringDate(newAnuncio.fechaExpiracion)}
                  type="date"
                  id="fecha_Expiracion"
                  name="fechaExpiracion" />
              </div>

            </div>


            <div className="row">
              <div className="col-12">

                <MdEditor
                  value={td.turndown(newAnuncio.mensaje)}
                  style={window.innerHeight > 1024 ? { height: "500px" } : { height: "400px" }}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={handleEditorChangeNewAnuncio}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <div>
                  <SavingIcon className="saving-icon"
                    isSaving={isSaving}
                    saved={docSaved}
                  />
                  <button className="btn btn-lg btn-success mt-2 " onClick={() => handleSaveNewAnuncio()} disabled={newAnuncio.mensaje === "" || newAnuncio.fechaExpiracion == null || newAnuncio.fechaPublicacion == null ? true : false}>
                    Crear anuncio
                 </button>


                </div>
              </div>
            </div>
          </>
          :
          null
      }
      {
        newAnuncio === null && (selectedAnuncio === null || selectedAnuncio === undefined)
          ?
          <div className="row mb-xl-3 row-sin-anuncio">
            <div className="col-12">

              <i> Seleccione un anuncio o cree uno nuevo</i>
            </div>
          </div>
          :

          null
      }
      {
        newAnuncio === null

          ?
          <NewAnuncioButton className="btn btn-lg btn-success mt-2 new-anuncio-btn shadow" onClick={() => createNewAnuncio()} >
            <span className="cross-button ">+</span>
          </NewAnuncioButton>
          :
          null

      }

    </div>
  )
}


export default ControlAnuncio
