
//session context
export const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
export const SET_ACTIVE_OPTION = "SET_ACTIVE_OPTION"
export const SET_LOADER_SECTION = "SET_LOADER_SECTION"
export const SET_LOADER_OPTION = "SET_LOADER_OPTION"
export const RENDER_CONTENT = "RENDER_CONTENT"
export const SET_LOGGED_USER = "SET_LOGGED_USER"

//gestion web context
export const SET_SELECTED_ANUNCIO = "SET_SELECTED_ANUNCIO"
export const CARGAR_ANUNCIOS_ACTIVOS = "CARGAR_ANUNCIOS"

//clientes context
export const SET_CLIENTES = "SET_CLIENTES"
export const SET_SELECTED_CLIENTE = "SET_SELECTED_CLIENTE"
export const SET_FACTURAS_CLIENTE = "SET_FACTURAS_CLIENTE"
