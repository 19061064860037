import styled from 'styled-components'
import ControlAnuncio from './ControlAnuncio.component'


export default styled(ControlAnuncio).attrs()`
   
   .form-label{
      font-weight: bold
   }
   .row-sin-anuncio{
      height: 400px;
    background-color: white;
    text-align: center;
    padding-top: 10%;
   }

   .saving-icon{
      position: absolute;
    right: 38%;
    top: 42%;
   }

.row-dates-selector{
   position: relative;
    right: 122px;
}
   
   .cross-button{
      position: relative;
    top: -24px;
   }
   
   `

   export const NewAnuncioButton = styled.button`
   
   
      position: relative;
    bottom: -43px;
    left: 690px;
    border-radius: 100%;
    height: 75px;
    width: 75px;
    font-size: 4rem;
   

   @media (max-width: 1024px) {
      
      position: relative;
    bottom: -43px;
    left: 384px;
    border-radius: 100%;
    height: 75px;
    width: 75px;
    font-size: 4rem;
   
   }
   
   
   `
 export const NewAnuncioCancelButton = styled.button`
   
   
         position: relative;
      left: 594px;
      float: left;

      @media (max-width: 1024px) {
      
         position: relative;
      left: 390px;
      float: left;

      }


`


