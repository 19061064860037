import React, { useEffect, useState,   } from 'react'
import firebase from '../firebase'
/**
 * Hook que detecta si se inicia sesion y guarda al usuario autenticado y detecta si se sale de la sesion
 */

function useAutenticacion(){

    const [usuarioAutenticado, guardarUsuarioAutenticado] = useState(null)


    useEffect(() => {
        
        const unsuscribe = firebase.auth.onAuthStateChanged(usuario =>{
            if( usuario ){
                guardarUsuarioAutenticado(usuario)
            }else{
                guardarUsuarioAutenticado(null)
            }
        })
        return () => unsuscribe()

    }, [])

    
    return usuarioAutenticado
}

export default useAutenticacion