import React, { useContext, useState } from 'react'
import { FirebaseContext } from '../../../../firebase'
import SavingIcon from '../../../UI/SavingIcon/SavingIcon.container'
import IframePDF from './IframePDF/IframePDF.container'
import Modal from '../../Modal/Modal.container'
const Horarios = ({ className }) => {

    const { firebase } = useContext(FirebaseContext)

    //state que almacena la url de la imagen
    const [pdfUrl, setpdfUrl] = useState(null)

    //state que almacena la linea selecionada
    const [selectedLinea, setselectedLinea] = useState(null)

    //state que almacena el archivo a subir
    const [uploadedFile, setuploadedFile] = useState(null)

    //state que almacena el progreso de la carga de archivo
    const [uploadProgress, setuploadProgress] = useState(null)
    const getHorarioUrl = async (linea) => {

        setpdfUrl(await firebase.getHorarioPDF(linea))

    }

    const handleFileUpload = (e) => {
        setuploadedFile(e.target.files[0])

    }

    //Handle modal:
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true)

    const uploadFile = () => {

        setuploadProgress("pending")
        const uploadProgress = firebase.uploadFile(uploadedFile, selectedLinea).then(UploadTaskSnapshot => {
            console.log(UploadTaskSnapshot)
            if (UploadTaskSnapshot.state === "success") {
                setuploadProgress("success")
                setuploadedFile(null)
                getHorarioUrl(selectedLinea)
                setTimeout(() => {
                    setShow(false)
                }, 1000);

            }
            if (UploadTaskSnapshot.task.error_) {
                setuploadProgress("failed")
                setShow(false)
            }
        })
        console.log(uploadProgress)
        // console.log(uploadProgress.on("state_changed", (snapshot) => {
        //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //     console.log('Upload is ' + progress + '% done');
        // }))

    }




    return (
        <div className={className}>
            <div className="row mt-5">
                <div className="col-10 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="pdf-select" className="label-select">Seleccione la linea de pedanía</label>
                        <select defaultValue={selectedLinea} className="form-control" id="pdf-select" onChange={(e) => {
                            setselectedLinea(e.target.value)
                            getHorarioUrl(e.target.value)
                        }}>
                            <option value={null}>--</option>
                            <option value="p1">p1</option>
                            <option value="p2">p2</option>
                            <option value="p3">p3</option>
                            <option value="p4">p4</option>
                            <option value="p6">p6</option>
                            <option value="p7">p7</option>
                        </select>
                    </div>
                </div>
                <div className="col-2 mt-4">
                    <button onClick={handleShow} className="btn btn-primary mt-3" disabled={selectedLinea === null ? true : false}>
                        Modificar
                </button>
                </div>

            </div>
            <div className="row">
                <div className="col-12">

                    {
                        pdfUrl === null
                            ?
                            null
                            :
                            <IframePDF pdfUrl={pdfUrl} />
                    }
                </div>
            </div>
            <Modal show={show}
                setShow={setShow}
                header="Selecciona un archivo"
                onConfirm={uploadFile}>
                <div className={className}>
                    <input type="file" onChange={(e) => handleFileUpload(e)} />
                    <SavingIcon className="saving-icon"
                        isSaving={uploadProgress === "pending" ? true : false}
                        saved={uploadProgress === "success" ? true : false}
                    />
                </div>
            </Modal>

        </div>
    )
}

export default Horarios
