import styled from 'styled-components'
import OpcionMenuLateral from './OpcionMenuLateral.component'



export default styled(OpcionMenuLateral).attrs()`
   
  
   .group{
      height: 60px;
      padding-left: 2rem;
    color: white;
   

   
   }

   .active {
      border-bottom: 4px solid var(--primary);
      background-color: #e0e0e0;
      transition: background-color 0.15s ease;
      transition: border 0.15s ease;
      
   }
   
   a:hover{
      text-decoration:none;
   }
   

   .options-icon{
      position: relative;
    top: 37%;
    left: 28%;
   }
   .external-icon{
      position: relative;
    top: 30%;
    left: 7%;
    margin-right: 16%;
   }

   .unactive:hover{
      border-left: 1.5rem solid var(--primary);
      transition: 0.15s;
      padding-left:2rem
      
      
      
   }

   .clickable:hover{
      cursor: pointer;
      
   }

   


   `
   export const Span = styled.span`
   
  
   @media (max-width: 1024px) {
      
         margin-top: 19px;
         font-size: 1rem;
         min-width: 0;
      
   }


   margin-top: 9px;
    font-size: 27px;
    min-width: 120px;

   `