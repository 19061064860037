import React, { useState, useEffect, useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Form, Button, Col, Row } from 'react-bootstrap'
import SavingIcon from '../../SavingIcon/SavingIcon.container'
import { FirebaseContext } from '../../../../firebase'

const NuevoCliente = ({ className }) => {


    //context de firebase
    const { usuario, firebase } = useContext(FirebaseContext)

  const [nuevoCliente, setnuevoCliente] = useState({
    direccion: null,
    nombre: null,
    ciudad: null,
    cif: null
  })

  const [endedForm, setendedForm] = useState(false)

  //estados para carga y confirmacion de la operacion de guardado 
  const [isSaving, setIsSaving] = useState(false)
  const [docSaved, setdocSaved] = useState(false)

  const handleChange = e => {

    setnuevoCliente({
      ...nuevoCliente,
      [e.target.name]: e.target.value
    })

    

  }
  const handleSubmit = e => {

    e.preventDefault()
    handleSaveNewCliente()


  }

  useEffect(() => {
   
    //comprobacion de que el formulario esta completado para habilitar el boton de guardado
    for(let params in Object.keys(nuevoCliente)){

      console.log()
      if(nuevoCliente[Object.keys(nuevoCliente)[params]] == null || nuevoCliente[Object.keys(nuevoCliente)[params]].trim() === ""){
        setendedForm(false)
        return;
      }
      
    }
    
    setendedForm(true)
    return;

  }, [nuevoCliente])

   //funcion para guardar el nuevo cliente
   const handleSaveNewCliente = async () => {

    
    //añadimos al nuevo anuncio un timestamp
    let cliente = nuevoCliente
    cliente.fechaAlta = new Date()
    //Iniciar estado de guardado para icono
    setIsSaving(true);
    //almacenar en base de datos
    await firebase.db.collection("clientes").add(cliente)

    //Establecer nuevo cliente a objeto vacio
    setnuevoCliente({
      direccion: null,
      nombre: null,
      ciudad: null,
      cif: null
    })

    //Terminar estado de guardado
    setIsSaving(false)
    //Establecer estado de documento guardado
    setdocSaved(true)


  }

  return (
    <div className={className} >
      <h3 className="mb-xl-4 mb-3 mt-5 ml-4">Alta de nuevo cliente:</h3>
      <i className=" ml-4">Introduzca los datos del cliente y pulse "Guardar".</i>
      <Col className="mt-5" xl={6}>
        <Form onSubmit={(e)=>handleSubmit(e)}>
          <Form.Group controlId="nombre">
            <Form.Label>Nombre:</Form.Label>
            <Form.Control type="text" name="nombre" value={nuevoCliente.nombre ? nuevoCliente.nombre : "" } placeholder="Introduce el nombre dle cliente..." onChange={(e) => handleChange(e)} />
          </Form.Group>

          <Form.Group controlId="cif">
            <Form.Label>CIF</Form.Label>
            <Form.Control type="text" name="cif" value={nuevoCliente.cif ? nuevoCliente.cif : ""} placeholder="Introduce el cif " onChange={(e) => handleChange(e)} />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId="direccion">
                <Form.Label>Dirección</Form.Label>
                <Form.Control type="text" name="direccion" value={nuevoCliente.direccion ? nuevoCliente.direccion : ""} placeholder="Introduce la dirección del cliente" onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="ciudad">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control type="text" name="ciudad" value={nuevoCliente.ciudad ? nuevoCliente.ciudad : ""} placeholder="Introduce la dirección del cliente" onChange={(e) => handleChange(e)} />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" disabled={!endedForm}>
            Guardar
        </Button>
        <SavingIcon className="saving-icon"
                    isSaving={isSaving}
                    saved={docSaved}
                  />
        </Form>
      </Col>
    </div>
  )
}


export default NuevoCliente
