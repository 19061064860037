import styled from 'styled-components'
import IframePDF from './IframePDF.component'


export default styled(IframePDF).attrs()`
   
   .saving-icon{
      font-size: 30px;
      position: relative;
      top: -40px;
      left: 400px;
   }

   `