import { SET_CLIENTES, SET_SELECTED_CLIENTE, SET_FACTURAS_CLIENTE } from '../../types'




export default (state, action) => {


    switch (action.type) {
        case SET_CLIENTES:
            return {
                ...state,
                clientes: action.payload
            }
        case SET_SELECTED_CLIENTE:
            return {
                ...state,
                selectedCliente: action.payload
            }
        case SET_FACTURAS_CLIENTE:
            return {
                ...state,
                facturasCliente: action.payload
            }
        default:
            return state;
    }
}