import React, { useContext, useEffect, useState } from 'react'

import InicioSesion from './components/UI/Login/InicioSesion/InicioSesion.container';
import UpperNavbar from './components/UI/Layout/UpperNavbar/UpperNavbar.container';
import { useHistory } from "react-router-dom";
import {
    BrowserRouter as Router,
    Switch,
    Route,
  
  } from "react-router-dom";

import sessionContext from './context/session/sessionContext'
import Spinner from './components/UI/Spinner/Spinner.container'
import ContentRenderer from './components/UI/Layout/ContentRenderer/ContentRenderer.container';

import { FirebaseContext } from './firebase'

/**
 * componente que contiene la barra de navegacion superior y alterna el spinner con el contenido principal
 * gestion el router 
 */
const AppContainer = () => {

    const { isLoadingSection, setLoadingSection } = useContext(sessionContext)

    const [history, setHistory] = useState(useHistory())
    
    const { usuario } = useContext(FirebaseContext);

    if(usuario == null){
        
        history.push('/login')
    }

    return (

        <>
            {
                usuario != null

                    ?
                    <UpperNavbar />
                    :
                    null
            }
            {
                isLoadingSection

                    ?
                    <div style={{ marginTop: "20%" }}>
                        <Spinner />
                    </div>
                    :
                    <div className="container-fluid p-0">
                        <Switch >
                            <Route exact path="/login" component={InicioSesion} />
                            <Route exact path="/:section/:option?" component={ContentRenderer} />
                        </Switch>
                    </div>
            }


        </>

    )
}

export default AppContainer
