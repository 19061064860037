import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Span} from './OpcionMenuLateral.styled'
import {
    Link

} from "react-router-dom";
import sessionContext from '../../../../context/session/sessionContext'


/**
 * 
 * @param {Object} content Objeto con la información para renderizar el componente 
 */

const OpcionMenuLateral = ({ isCollapsed, activeOption, setactiveOption, content, className }) => {

    const { text, iconClass,  optionId } = content

    const { setLoadingOption, activeSection } = useContext(sessionContext)


    const handleClick = () => {

        setactiveOption(optionId)
        setLoadingOption()
        return;

    }
    return (
        <div className={className} >
        {/*SectionId se compara con la opcion activa para comprobar que esta activa */}
            <Link 
            to={`/${activeSection}/${optionId}`}
            className={activeOption === optionId ? "row group  align-middle active" : "row group clickable unactive align-middle"} 
            onClick = {handleClick}
            >
                

                <FontAwesomeIcon icon={iconClass} className="external-icon clickable" size={"lg"}
                    color="#2a2b33"
                />
                {
                    isCollapsed

                    ? 
                    null
                    :
                    <Span className="align-middle span">{text}</Span>

                }



            </Link>
        </div>
    )
}


export default OpcionMenuLateral
