import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ClientesState from '../../../../context/clientes/clientesState'

import TablaClientes from './TablaClientes/TablaClientes.container'
import DetalleCliente from './DetalleCliente/DetalleCliente.container'



const Explorar = ({ className }) => {







    return (
        <div className={className} >


            <ClientesState>
                <div className="row " style={{ paddingBottom: "5rem", marginBottom: "5rem", opacity: "1" }}>
                    <div className="col-12">
                        <div className="row mt-5 ml-xl-5 ml-3 ">
                            <TablaClientes />
                        </div>

                        <DetalleCliente className="row mt-5 ml-xl-5" />

                    </div>
                </div>
            </ClientesState>


        </div>
    )
}


export default Explorar
