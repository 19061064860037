import styled from 'styled-components'
import Horarios from './Horarios.component'


export default styled(Horarios).attrs()`

   .label-select{
      font-weight: bold;
      font-size: 1.5rem;
   }

  
   

   `