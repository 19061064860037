import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const ConceptoFacturaInput = ({ className, concepto, setConcepto }) => {



    useEffect(() => {

    }, [concepto])
    //state que almacena el concepto para edicion
    const [state, setstate] = useState(concepto)

    const { descripcion, fecha, precioUnidad, precioTotal, unidad, iid } = state

    const handleChange = e => {

        let value = e.target.value

        if (e.target.name === "unidad") {

            value = parseInt(e.target.value)
            setstate({
                ...state,
                [e.target.name]: value,
                precioTotal: value * state.precioUnidad
            })
        }
        else if (e.target.name === "precioUnidad") {

            value = parseFloat(value)

            setstate({
                ...state,
                [e.target.name]: value,
                precioTotal: value * state.unidad
            })
        }
        else if (e.target.name === "precioTotal") {
            value = parseFloat(e.target.value)
        }
        else {
            setstate({
                ...state,
                [e.target.name]: value
            })
        }








    }

    useEffect(() => {




        setConcepto(state)


    }, [state])



    return (
        <div className={className} >


            <div className="col-2 concepto p-0">
                <input
                    value={fecha || ""}
                    type="date"
                    onChange={(e) => handleChange(e)}
                    name="fecha"
                    id="input-fecha" />
            </div>
            <div className="col-xl-5 col-5 conceptos">
                <input
                    value={descripcion || ""}
                    type="text"
                    onChange={(e) => handleChange(e)}
                    name="descripcion"
                    id="input-descripcion" />
            </div>
            <div className="col-1 concepto">
                <input
                    value={unidad || ""}
                    type="number"
                    min="0"
                    step="1"
                    onChange={(e) => handleChange(e)}
                    name="unidad"
                    id="input-unidad" />
            </div>
            <div className="col-1 concepto">
                <input
                    value={precioUnidad || ""}
                    type="number"
                    min="0"
                    onChange={(e) => handleChange(e)}
                    name="precioUnidad"
                    id="input-precio-unidad" />
            </div>
            <div className="col-2 concepto">
                <input
                    value={precioUnidad * unidad || ""}
                    type="number"
                    min="0"
                    onChange={(e) => handleChange(e)}
                    name="precioUnidad"
                    id="input-precio-total" />
            </div>




        </div>
    )
}


export default ConceptoFacturaInput
