import styled from 'styled-components'
import FormularioDetallesCliente from './FormularioDetallesCliente.component'


export default styled(FormularioDetallesCliente).attrs()`
   
   .cancel-edit-icon{
      color: grey;
    font-size: 1.5rem;
    position: relative;
    top: 5px;
    left: 10px;
   }

   .saving-icon{
      position: relative;
    top: -30px;
    left: 81px;
   }

   `