import {
    SET_SELECTED_ANUNCIO, CARGAR_ANUNCIOS_ACTIVOS
} from '../../types'




export default (state, action) => {


    switch (action.type) {
        case SET_SELECTED_ANUNCIO:
            return {
                ...state,
                anuncios: {
                    ...state.anuncios,
                    selectedAnuncio: action.payload
                }
            }
        case CARGAR_ANUNCIOS_ACTIVOS:
            return {
                ...state,
                anuncios: {
                    ...state.anuncios,
                    anunciosActivos: action.payload
                }
            }
        default:
            return state;
    }
}