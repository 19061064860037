import React, { useReducer } from 'react'

import {
    SET_CLIENTES, SET_SELECTED_CLIENTE, SET_FACTURAS_CLIENTE
} from '../../types';

import clientesContext from './clientesContext';

import clientesReducer from './clientesReducer';

const ClientesState = props => {



    //Inicialización del state
    const initialState = {

        clientes: [],
        selectedCliente: null,
        facturasCliente: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(clientesReducer, initialState);

    //funcion para asignar  los clientes
    const setClientes = clientes => {
        dispatch({
            type: SET_CLIENTES,
            payload: clientes
        })
    }
    //funcion para seleccionar un cliente
    const selectCliente = cliente => {

        dispatch(
            {
                type: SET_SELECTED_CLIENTE,
                payload: cliente
            }
        )
    }

    const setFacturasCliente = facturas => {
        dispatch({
            type: SET_FACTURAS_CLIENTE,
            payload: facturas
        })
    }

    return (
        <clientesContext.Provider

            value={{
                clientes: state.clientes,
                selectedCliente: state.selectedCliente,
                facturasCliente: state.facturasCliente,

                setClientes,
                selectCliente,
                setFacturasCliente
            }}

        >
            {props.children}
        </clientesContext.Provider>
    )
}

export default ClientesState;


