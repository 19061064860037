import React, { useContext } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Logo } from './UpperNavbar.styled'
import { useHistory } from "react-router-dom";

import UpperNavbarLink from '../UpperNavbarLink/UpperNavbarLink.container';
import { FirebaseContext } from '../../../../firebase'
import sessionContext from '../../../../context/session/sessionContext'

const navbarContent = [
    {
        texto: "Inicio",
        sectionId: "inicio",
        link: "/inicio"
    },
    {
        texto: "Gestión Web",
        sectionId: "gestionWeb",
        link: "/gestionWeb"
    },
    {
        texto: "Clientes",
        sectionId: "clientes",
        link: "/clientes"
    }

]


const UpperNavbar = ({ className }) => {

    let history = useHistory();

    const { setLoadingSection } = useContext(sessionContext)
    const { usuario, firebase } = useContext(FirebaseContext)


    return (
        <div className={className}>
            <Navbar expand="lg" >
                <Navbar.Brand href="/" >
                <Logo>
                <img src={process.env.PUBLIC_URL + '/SALVER_LOGO.png'} onClick={() => {
                    history.push('/inicio')
                }} width="120" /> 
                </Logo>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {
                            navbarContent.map((content, index) => (
                                <UpperNavbarLink
                                    key={index}
                                    content={content}
                                />
                            ))
                        }
                    </Nav>

                </Navbar.Collapse>
                <p className="user-name">Hola {usuario.displayName}</p>
                <button className="btn btn-primary" onClick={() => { setLoadingSection(); firebase.cerrarSesion(); }}>Cerrar Sesión </button>
            </Navbar>
        </div>
    )
}


export default UpperNavbar
