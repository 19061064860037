import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

import sessionContext from '../../../../context/session/sessionContext'


const UpperNavbarLink = ({ className, content }) => {

    const { activeSection, setActiveSection, setLoadingSection } = useContext(sessionContext)
    //el link al componente se encuentra en el context
    const { texto, sectionId, link } = content

    const handleClick = newActiveSection => {
        setActiveSection(newActiveSection);
        setLoadingSection()
    }


    return (
        <div className={className} >
           <Link 
                        className={activeSection === sectionId ? "nav-link mr-5 active"  : "nav-link mr-5" }
                        onClick={() => handleClick(sectionId)} 
                        to={link}>{texto}</Link>
        </div>
    )
}


export default UpperNavbarLink
