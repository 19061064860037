import React, {  useState } from 'react'
import TablaAnuncios from './TablaAnuncios/TablaAnuncios.container'

import GestionWebState from '../../../../context/gestionWeb/gestionWebState'
import ControlAnuncio from './ControlAnuncio/ControlAnuncio.container'

const Anuncios = ({ className }) => {


   const [hasLoad, sethasLoad] = useState(false)

    return (
        <div className={className}>
            
                    
                    <GestionWebState>
                        <div className="row " style={{ paddingBottom: "5rem", marginBottom: "5rem"}}>
                            <div className="col-5 col-xl-4 mt-5 ml-xl-5">
                                <TablaAnuncios hasLoad={hasLoad}/>
                            </div>
                            <div className="col-7 ml-xl-5 col-xl-6  mt-5">
                                <ControlAnuncio sethasLoad={sethasLoad} />
                            </div>
                        </div>
                    </GestionWebState>

            

        </div>
    )
}

export default Anuncios
