import styled from 'styled-components'
import MenuLateral from './MenuLateral.component'


export default styled(MenuLateral).attrs()`
   

   

   a, span, p{
      font-family: 'Nunito', sans-serif;
      color: #2a2b33;
   }
   .sidebar{
      height: 100vh;
     color: #2a2b33;
      background-color: blue
   }

   .clickable:hover{
      cursor:pointer
   }

   .collapse-icon{
      position: absolute;
      top: 1%;
      left: 86%;

   }

   `