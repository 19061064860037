import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Iframe from 'react-iframe'

const IframePDF = ({ className, pdfUrl}) => {

   

    return (
        <div className={className} >
          <Iframe url={pdfUrl}
                        width={ window.innerWidth <= 1024 ? "800px" : "1400px"}
                        height={ window.innerWidth <= 1024 ? "400px" : "700px"}
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />
        </div>
    )
}


export default IframePDF
