import {
    SET_ACTIVE_SECTION, SET_LOADER_SECTION,SET_LOADER_OPTION, SET_ACTIVE_OPTION, RENDER_CONTENT, SET_LOGGED_USER
} from '../../types'




export default (state, action) => {


    switch (action.type) {
        case SET_LOGGED_USER:
            return {
                ...state,
                loggedUser: action.payload
            }
        
        case SET_ACTIVE_SECTION:
            return {
                ...state,
                activeSection: action.payload
            }
            
        case SET_ACTIVE_OPTION:
            return {
                ...state,
                activeOption: action.payload
            }
        case SET_LOADER_SECTION:
            return {
                ...state,
                isLoadingSection: action.payload
            }
        case SET_LOADER_OPTION:
            return {
                ...state,
                isLoadingOption: action.payload
            }
        case RENDER_CONTENT:
            return {
                ...state,
                sectionContent: action.payload
            }
        default:
            return state;
    }
}