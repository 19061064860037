
//contiene la configuración de firebase
const firebaseConfig = {
    apiKey: "AIzaSyCQbTw6xWqMn1q4PlRTi66kMMVIctmHr5w",
    authDomain: "autos-la-valenciana.firebaseapp.com",
    databaseURL: "https://autos-la-valenciana.firebaseio.com",
    projectId: "autos-la-valenciana",
    storageBucket: "gs://autos-la-valenciana.appspot.com",
    messagingSenderId: "285628792912",
    appId: "1:285628792912:web:3ba944f35f45df15441155",
    measurementId: "G-RGKTELPYPK"
}

export default firebaseConfig