import styled from 'styled-components'
import TablaClientes from './TablaClientes.component'


export default styled(TablaClientes).attrs()`
   
   .table{
         background-color: #f3f3f3;
    max-height: 500px;
    display: block;
    overflow-y: scroll;
      }
      .selected-row{
         background-color: #dedede
      }

      tbody{
         background-color: white

      }
      tbody:hover{
         cursor:pointer
      }
      tr:hover{
         background-color: #dedede
      }
      thead{
         background-color: white
      }
      tbody:before {
         content:"@";
         display:block;
         line-height:10px;
         text-indent:-99999px;
         background-color: #f3f3f3

      }
      td{
         max-width: 150px;
         min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
      }
      
      .filter-input{
         background: #dadada;
         border: none;
         padding: 5px;
         margin-left: 20px;

         &:focus{
            border: none;
            border-bottom: 0.5px solid var(--primary);
            outline: none;
      transition: border 0.25s ease;

         }

      }

     
      
      .search-icon{
         font-size: 1.4rem;
         position: relative;
         top: 4px;

      }

      .filtrar-label{
         font-size: 1.2rem
      }

   `