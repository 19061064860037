import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Inicio = ({ className}) => {
    return (
        <div className={className} >
          <div className="row justify-content-center texto">
          
              Bienvenido a Fénix, la plataforma web de gestión de la empresa Auto La Valenciana.
              <br/>
              En esta sección aparecerá información general, pero aún está en desarrollo. Mientras tanto, explora otras secciones utilizando la barra de navegación.
              </div>
         
        </div>
    )
}


export default Inicio
