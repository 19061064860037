import styled from 'styled-components'
import Inicio from './Inicio.component'


export default styled(Inicio).attrs()`
   
   .texto{
      font-size: 1.5rem;
    text-align: center;
    padding-top: 11rem;
    font-style: italic;
    font-weight: bold;
   }

   `