import styled from 'styled-components'
import EditorFacturas from './EditorFacturas.component'


export default styled(EditorFacturas).attrs()`
   
   .contenedor-facturas{
      background-color: white;
    min-height: 500px;
    min-width: 100%;
    
    
   }

   .header-conceptos{

      background-color: #d8d8d8;
      padding: 2.5px;
      padding-top:5px;
      padding-bottom:5px;
      margin-right: 2.5px;
      text-align:center;



   }

   #input-iva-factura{
      max-width: 67px;
    margin-left: 1rem;
   }

   `