import React, { useReducer, useEffect } from 'react'

import {
    SET_ACTIVE_SECTION, SET_LOADER_SECTION, SET_ACTIVE_OPTION, RENDER_CONTENT, SET_LOADER_OPTION, SET_LOGGED_USER
} from '../../types';

import sessionContext from './sessionContext';

import sessionReducer from './sessionReducer';

const SessionState = props => {

    //INformación para renderizar secciones y opciones
    const sections = {
        gestionWeb: {
            options: [
                {
                    text: "Anuncios",
                    iconClass: "bullhorn",
                    optionId: "anuncios"
                },
                {
                    text: "Horarios",
                    iconClass: "clock",
                    optionId: "horarios"
                }
            ]

        },
        inicio: {
            options: [
              
            ]

        },
        clientes:{
            options:[
                {
                    text: "Explorar",
                    iconClass: "search",
                    optionId: "explorar"
                },
                {
                    text: "Alta",
                    iconClass: "user-plus",
                    optionId: "nuevoCliente"
                }
                
            ]
        }

    }

    //Inicialización del state
    const initialState = {
        isLoadingSection: false,
        isLoadingOption: false,
        activeSection: "clientes",
        activeOption: sections["clientes"].options[0].sectionId,
        sectionContent: sections["clientes"],
        loggedUser: null
    }


    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(sessionReducer, initialState);

    //Añade el usuario logeado al state
    const setLoggedUser = (usuario) =>{
        dispatch(
            {
                type: SET_LOGGED_USER,
                payload: usuario
            }
        )
    }
    //Modificar el estado de la seccion actual
    const setActiveSection = (newSection) => {
        dispatch(
            {
                type: SET_ACTIVE_SECTION,
                payload: newSection
            }
        )
            if(sections[newSection].options.length > 0){
                setActiveOption(sections[newSection].options[0].optionId)
            }
            else{
                setActiveOption(null)
            }
        
    }
     //Modificar el estado de la opcion actual dentro de la seccion
     const setActiveOption = (newOption) => {
        dispatch(
            {
                type: SET_ACTIVE_OPTION,
                payload: newOption
            }
        )

    }
    //Modificar el estado del contenido de la opcion actual
    const renderContent = () => {

        dispatch(
            {
                type: RENDER_CONTENT,
                payload: sections[state.activeSection]
            }
        )
    }

    //useEffect para modificar el contenido del estado de una seccion cuando se cambia entre secciones
    useEffect(() => {
        renderContent()
    }, [state.activeSection])

    //Modificar el estado del loader para cambio de seccion
    const setLoadingSection = () => {

        //Al iniciarse, establece isLoading a true
        dispatch(
            {
                type: SET_LOADER_SECTION,
                payload: true
            })
        //a los dos segundos, lo restablece a false
        setTimeout(() => {
            dispatch(
                {
                    type: SET_LOADER_SECTION,
                    payload: false
                })
        }, 1200);




    }
    const modifyLoadingOption = (value) =>{
        dispatch({
            type: SET_LOADER_OPTION,
            payload: value
        })
    }

     //Modificar el estado del loader para cambio de opcion
     const setLoadingOption = () => {

        //Al iniciarse, establece isLoading a true
        dispatch(
            {
                type: SET_LOADER_OPTION,
                payload: true
            })
        //a los dos segundos, lo restablece a false
        setTimeout(() => {
            dispatch(
                {
                    type: SET_LOADER_OPTION,
                    payload: false
                })
        }, 1);




    }


    return (
        <sessionContext.Provider

            value={{
                activeSection: state.activeSection,
                isLoadingSection: state.isLoadingSection,
                isLoadingOption: state.isLoadingOption,
                activeOption: state.activeOption,
                sectionContent: state.sectionContent,
                loggedUser: state.loggedUser,
                
                setActiveSection,
                setLoadingSection,
                setLoadingOption,
                setActiveOption,
                setLoggedUser,
                modifyLoadingOption
            }}

        >
            {props.children}
        </sessionContext.Provider>
    )
}

export default SessionState;