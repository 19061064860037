import React, { useState, useEffect, useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FirebaseContext } from '../../../../../../firebase'
import Spinner from '../../../../Spinner/Spinner.container'
import ConceptoFacturaInput from './ConceptoFacturaInput/ConceptoFacturaInput.container'
import SavingIcon from '../../../../SavingIcon/SavingIcon.container'

/**
 * Componente para mostrar el editor de facturas cuando se sempieza a crear una factura
 * @param {object} cliente Objeto del cliente seleccionado 
 */
const EditorFacturas = ({ className, cliente, seteditingFactura }) => {

    const { firebase } = useContext(FirebaseContext)

    //estado  para loader
    const [hasLoad, sethasLoad] = useState(false)

    //estado para almacenar el id de la ultima factura
    const [lastNumeroFactura, setlastNumeroFactura] = useState(null)

    //estado para almacenar al factura en creacion
    const [factura, setfactura] = useState({
        fechaFactura: null,
        conceptos: [],
        idCliente: cliente.id,
        numeroFactura: null,
        iva: null,
        subtotal: null,
        total: null

    })
    //estado para almacenar los resultados de una factura
    const [resultadosFactura, setresultadosFactura] = useState({
        subtotal: 0,
        iva: 0,
        total: 0

    })
    //estado para almacenar el iva que aplica a la factura
    const [ivaFactura, setivaFactura] = useState(10.00)

    //cargar el ultimo id de factura de la coleccion de configuracion
    useEffect(() => {
        const obtenerConfiguracion = async () => {

            await firebase.db.collection("configuracion")
                .onSnapshot(handleSnapshot);

        }

        obtenerConfiguracion()
    }, [])


    //funcion para procesar el resultado de la consulta a firestore
    function handleSnapshot(snapshot) {

        const configuracion = snapshot.docs.map(doc => {
            return {

                ...doc.data()
            }
        })
        //console.log(anuncios)
        //set anuncios activos en el state
        setlastNumeroFactura(configuracion[0].clientes.lastNumeroFactura)
        setfactura({
            ...factura,
            numeroFactura: renderStringFactura(configuracion[0].clientes.lastNumeroFactura + 1)
        })
        sethasLoad(true)

    }

    //funcion para añadir un nuevo concepto a la factura
    const addNewConcepto = () => {

        let newConcepto = {
            descripcion: "",
            fecha: null,
            iid: getLastIidConcepto(factura) + 1,
            precioTotal: null,
            precioUnidad: null,
            unidad: null
        }

        //añadimos el objeto nuevo concepto a los conceptos de la factura y actualizamos
        setfactura({
            ...factura,
            conceptos: [
                ...factura.conceptos,
                newConcepto
            ]
        })

    }

    //funcion para editar un concepto dentro de una factura
    const setConcepto = (concepto) => {


        if (factura.conceptos.length == 0) {
            return
        }
        else {

            //identificar el indice del concepto editado dentro del array de conceptos de la factura
            let indexConcepto;
            factura.conceptos.map((_concepto, index) => {
                if (_concepto.iid == concepto.iid) {
                    indexConcepto = index
                }
            })


            //modificar el array de conceptos de la factura
            let facturaEdit = factura
            facturaEdit.conceptos[indexConcepto] = concepto
            //actualizar el estado de factura
            setfactura(facturaEdit);

            //cada vez que se modifique un concepto, recalculamos totales

            let resultados = calculateAgregatesConceptos(factura)

            setresultadosFactura({

                iva: resultados.iva.toFixed(2),
                subtotal: resultados.subtotal.toFixed(2),
                total: resultados.total.toFixed(2)
            })

        }



    }
    //estado para almacenaer error en proceso de guardado
    const [errorguardar, seterrorguardar] = useState(null)

    //estados para carga y confirmacion de la operacion de guardado 
    const [isSaving, setIsSaving] = useState(false)
    const [docSaved, setdocSaved] = useState(false)

    //funcion para guardar la nueva factura en firebase
    const guardarFactura = async () => {

        //primero realizamos validacion de campos
        seterrorguardar(validarFactura(factura))
        //si pasa la validacion, guardamos los campos agregados
        if (validarFactura(factura) === "") {

            setIsSaving(true);

            let new_factura = factura

            new_factura.iva = parseFloat(resultadosFactura.iva);
            new_factura.subtotal = parseFloat(resultadosFactura.subtotal);
            new_factura.total = parseFloat(resultadosFactura.total);
            new_factura.timestamp = new Date();

            setfactura(new_factura)

            //Iniciar estado de guardado para icono

            //almacenar en base de datos
            await firebase.db.collection("facturas").add(new_factura)


            //Terminar estado de guardado
            setIsSaving(false)

            //actualizamos el valor del ultimo id de factura

            const actualizarConfiguracion = async () => {

                await firebase.db.collection("configuracion")
                    .onSnapshot(handleSnapshotActualizar);

            }

            //funcion para procesar el resultado de la consulta a firestore
            async function handleSnapshotActualizar(snapshot) {

                const configuracion = snapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                })
                //console.log(anuncios)
                //set anuncios activos en el state
                setlastNumeroFactura(configuracion[0].clientes.lastNumeroFactura)
                let new_configuracion = configuracion[0]
                new_configuracion.clientes.lastNumeroFactura = lastNumeroFactura + 1
                const updated = await firebase.db.collection("configuracion").doc(new_configuracion.id).update(new_configuracion)


            }

            actualizarConfiguracion()


            //Establecer estado de documento guardado
            setdocSaved(true)

            
            //desactivamos el editar factura
          
                seteditingFactura(false)
           

        }

    }

    useEffect(() => {
        let resultados = calculateAgregatesConceptos(factura)

        setresultadosFactura({

            iva: resultados.iva.toFixed(2),
            subtotal: resultados.subtotal.toFixed(2),
            total: resultados.total.toFixed(2)
        })
    }, [ivaFactura])

    //funcion para calcular los valores agregados de la factura
    function calculateAgregatesConceptos(factura) {


        let subtotal = 0

        factura.conceptos.map((concepto) => {
            subtotal += concepto.precioTotal
        })

        let iva = ivaFactura / 100 * subtotal;
        let total = subtotal * (1 + ivaFactura / 100)



        return {
            subtotal,
            iva,
            total

        }

    }

    return (
        <div className={className} >
            {
                !hasLoad

                    ?
                    <div style={{ marginTop: "20%" }}>
                        <Spinner />
                    </div>
                    :
                    //edicion del numero y la fecha de factura
                    <div className="contenedor-facturas  pt-2 container justify-content-center" >
                        <p className="d-block mt-3" style={{ fontWeight: "bold", fontSize: "1.3rem" }}>Número de factura: {factura.numeroFactura}</p>
                        <div className="col-3 pl-0 d-inline">
                            <label htmlFor="fecha_factura" className="form-label" style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Fecha factura:</label>
                        </div>
                        <div className=" col-6 d-inline ">
                            <input
                                value={factura.fechaFactura || ""}
                                type="date"
                                onChange={(e) => (setfactura({
                                    ...factura,
                                    fechaFactura: e.target.value
                                }))}
                                id="fecha_factura"
                                name="fechaFactura" />
                        </div>
                        <hr />
                        <div className="row justify-content-center">

                            <div className="col-12">
                                <div className="row justify-content-center mb-2">
                                    <div className="col-2 header-conceptos">Fecha</div>
                                    <div className="col-xl-5 col-5 header-conceptos">Descripción</div>
                                    <div className="col-1 header-conceptos">Un.</div>
                                    <div className="col-1 header-conceptos">P. Un.</div>
                                    <div className="col-2 header-conceptos">Total</div>

                                </div>
                                {

                                    factura.conceptos.map((concepto, index) => (
                                        <ConceptoFacturaInput
                                            key={index}
                                            concepto={concepto}
                                            setConcepto={setConcepto}
                                            className="row mb-3 justify-content-center"
                                        />
                                    ))

                                }
                                {
                                    factura.conceptos.length > 0

                                        ?
                                        <>

                                            <div className="row justify-content-center mb-2">
                                                <div className="col-2 offset-7 header-conceptos">SUBTOTAL:</div>
                                                <div className="col-2  header-conceptos">{resultadosFactura.subtotal}</div>
                                            </div>
                                            <div className="row justify-content-center mb-2">
                                                <div className="col-2 offset-7 header-conceptos">
                                                    <span>IVA:</span>
                                                    <input
                                                        type="number"
                                                        value={ivaFactura.toString() || ""}
                                                        min="0"
                                                        step="0.01"
                                                        onChange={(e) => setivaFactura(parseFloat(e.target.value))}
                                                        id="input-iva-factura"
                                                    />
                                                    <span> %</span>
                                                </div>
                                                <div className="col-2  header-conceptos">{resultadosFactura.iva}</div>
                                            </div>
                                            <div className="row justify-content-center mb-2">
                                                <div className="col-2 offset-7 header-conceptos">TOTAL:</div>
                                                <div className="col-2  header-conceptos">{resultadosFactura.total}</div>
                                            </div>

                                        </>
                                        :

                                        null
                                }


                                <div className="row justify-content-center">
                                    <button onClick={() => addNewConcepto()} className="btn btn-outline-secondary btn-sm mt-5 mb-3"><FontAwesomeIcon icon="plus" className="mr-2" />Añadir concepto</button>
                                </div>
                                {
                                    errorguardar !== "" && errorguardar != null

                                        ?
                                        <div className="row justify-content-center">
                                            <div className="alert alert-danger" role="alert">
                                                {
                                                    errorguardar
                                                }
                                            </div>
                                        </div>
                                        :
                                        null

                                }

                                {
                                    factura.conceptos.length > 0

                                        ?

                                        <div className="row justify-content-center">
                                            <button onClick={() => guardarFactura()} className="btn btn-success btn-sm mr-3 mb-3 "><FontAwesomeIcon icon="save" className="mr-2" />Guardar factura</button>
                                            <SavingIcon className="saving-icon "
                                                isSaving={isSaving}
                                                saved={docSaved}
                                            />
                                        </div>

                                        :

                                        null
                                }
                            </div>
                        </div>


                    </div>




            }
        </div>
    )
}


export default EditorFacturas

//funcion para crear el string de numero de factura
function renderStringFactura(idUltimaFactura) {

    

    if (idUltimaFactura.toString().length < 2) {
        return "00" + idUltimaFactura.toString() + "/" + new Date().getFullYear().toString()
    }
    else if (idUltimaFactura.toString().length < 3) {
        return "0" + idUltimaFactura.toString() + "/" + new Date().getFullYear().toString()
    }
    else {
        return idUltimaFactura.toString() + "/" + new Date().getFullYear().toString()
    }


}

//funcion para obtener el id interno del ultimo concepto de una factura
function getLastIidConcepto(factura) {


    return factura.conceptos.length > 0 ? factura.conceptos[factura.conceptos.length - 1].iid : 0

}


//funcion para validar la factura antes de guardarla

function validarFactura(factura) {

    //comprobamos en cada uno de los conceptos que no haya ningun campo vacio
    for (let concepto in factura.conceptos) {

        let conceptoActual = factura.conceptos[concepto]

        for (let campo in conceptoActual) {
            if (conceptoActual[campo] == null) {
                return "Revisa los campos vacios"
            }
            else if (conceptoActual[campo] === "") {
                return "Revisa los campos vacios"
            }
        }


    }
    //despues revisamos el campo fecha de la factura
    if (factura.fechaFactura === "" || factura.fechaFactura == null) {
        return "Revisa la fecha de la factura"
    }

    return ""


}
