import  axios  from "axios"

export const facturasService = {

    getFactura : async function (factura){

        return await (await axios.post("http://104.199.59.150:8083/api/v1/facturas", factura)).data

    }


}
