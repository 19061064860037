import React, { useContext, useMemo, useEffect, useState } from 'react'
import { useTable } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clientesContext from '../../../../../context/clientes/clientesContext'
import { FirebaseContext } from '../../../../../firebase'
import Spinner from '../../../Spinner/Spinner.container'
const TablaClientes = ({ className }) => {

    const { firebase } = useContext(FirebaseContext)

    //console.log("rendering table")

     //estados para el filtrado 
     const [filterValue, setfilterValue] = useState("")

     //estado  para loader
     const [hasLoad, sethasLoad] = useState(false)
    //contexto de clientes
    const { clientes, selectedCliente, selectCliente, setClientes } = useContext(clientesContext)

    const data = useMemo(() => clientes, [clientes, selectedCliente, filterValue])


    useEffect(() => {


        const obtenerClientes = async () => {

            await firebase.db.collection("clientes").orderBy("fechaAlta", "desc")
                .onSnapshot(handleSnapshot);

        }

        obtenerClientes()
        

    }, [])

    //funcion para procesar el resultadod e la consulta a firestore
    function handleSnapshot(snapshot) {

        const clientes = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        })
        //console.log(anuncios)
        //set anuncios activos en el state
        setClientes(clientes)
        sethasLoad(true)

    }

    //Define the columns
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Nombre',
                accessor: 'nombre', // accessor is the "key" in the data
            },
            {
                Header: 'CIF',
                accessor: 'cif',
            },
            
            {
                Header: 'Ciudad',
                accessor: 'ciudad',
            },
            {
                Header: 'Dirección',
                accessor: 'direccion',
            },
            

        ],
        []
    )


    //Retrieve properties from useTable hook of react-table package passing 
    //columns and data variables just created
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })


   
    

    return (
        <div className={className} >
           
                {
                    !hasLoad 
                    

                    ?
                    <div style={{ marginTop: "20%", marginLeft:"200px" }}>
                        <Spinner />
                    </div>
                    :
                    
                        <>
                            <h4 className="mb-3">Clientes</h4>
                            <div >
                            <label className="filtrar-label">Filtrar:</label>
                            <input type="text" name="filter" className="filter-input" value={filterValue || ""} onChange={(e)=>setfilterValue(e.target.value)} />
                            <FontAwesomeIcon icon="search" className="ml-3 search-icon"/>
                            </div>
                            <table {...getTableProps()} className="table mt-3">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))}

                                        </tr>
                                    ))}

                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row, index) => {

                                        //filter logic
                                        //iterar por los valores de la fila y comprobar si contiene el filtro
                                        let includes = false
                                        for(let param in Object.keys(row.values)){

                                            if(row.values[Object.keys(row.values)[param]].includes(filterValue) && filterValue.trim() !== ""){
                                                includes = true
                                            }


                                        }

                                        if(!includes &&  filterValue.trim() !== "") return;


                                        row.values.id = row.values.id.substring(0, 5)
                                        prepareRow(row)
                                        //console.log(row)
                                        let className = "";
                                        if (selectedCliente !== null) {

                                            try {
                                                
                                                className = data[index].id === selectedCliente.id ? "selected-row " : ""

                                            }
                                            catch{

                                            }


                                        }

                                        return (
                                            <tr {...row.getRowProps()} className={className} onClick={() => {
                                                console.log(data[index].id)
                                                selectCliente(data[index])
                                            }}>
                                                {row.cells.map(cell => {

                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}

                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </>
                        

                }
            </div>
        
    )
}


export default TablaClientes
