import styled from 'styled-components'
import SavingIcon from './SavingIcon.component'


export default styled(SavingIcon).attrs()`
   
   @keyframes spinner {
  to { transform: rotate(360deg); }
}
/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
}

.check{
   color: var(--success)
}
.none{
   display: none
}
   `