import React, { useReducer, useEffect } from 'react'

import {
    SET_SELECTED_ANUNCIO, CARGAR_ANUNCIOS_ACTIVOS
} from '../../types';

import gestionWebContext from './gestionWebContext';

import gestionWebReducer from './gestionWebReducer';

const GestionWebState = props => {



    //Inicialización del state
    const initialState = {
        anuncios:{
            
                anunciosActivos:[],
                selectedAnuncio: null
            
        }
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(gestionWebReducer, initialState);

    const selectAnuncio = id =>{
        dispatch({
            type: SET_SELECTED_ANUNCIO,
            payload: state.anuncios.anunciosActivos.filter(item => item.id === id)[0]
        })
    } 
    const setSelectedAnuncio = anuncio =>{
        dispatch({
            type: SET_SELECTED_ANUNCIO,
            payload:anuncio
        })
    } 


    
    const cargarAnunciosActivos = anuncios => {
        dispatch({
            type: CARGAR_ANUNCIOS_ACTIVOS,
            payload: anuncios
        })
    }

   
    return (
        <gestionWebContext.Provider

            value={{
                anuncios: state.anuncios,
                selectedAnuncio: state.anuncios.selectedAnuncio,

                selectAnuncio,
                cargarAnunciosActivos,
                setSelectedAnuncio
            }}

        >
            {props.children}
        </gestionWebContext.Provider>
    )
}

export default GestionWebState;


