import styled from 'styled-components'
import TablaAnuncios from './TablaAnuncios.component'


export default styled(TablaAnuncios).attrs()`

      .table{
         background-color: #f3f3f3;
    max-height: 500px;
    display: block;
    overflow-y: scroll;
      }
      .selected-row{
         background-color: #dedede
      }

      tbody{
         background-color: white

      }
      tbody:hover{
         cursor:pointer
      }
      tr:hover{
         background-color: #dedede
      }
      thead{
         background-color: white
      }
      tbody:before {
         content:"@";
         display:block;
         line-height:10px;
         text-indent:-99999px;
         background-color: #f3f3f3

      }


   

   `