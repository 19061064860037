import React, { useContext, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTable } from 'react-table'
import Spinner from '../../../Spinner/Spinner.container'
import gestionWebContext from '../../../../../context/gestionWeb/gestionWebContext'


const TablaAnuncios = ({ className, hasLoad }) => {

    const { anuncios, selectedAnuncio, selectAnuncio } = useContext(gestionWebContext)

    const data = useMemo(() => anuncios.anunciosActivos, [anuncios, selectedAnuncio])



    //Define the columns
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Fecha publicación',
                accessor: 'fechaPublicacion', // accessor is the "key" in the data
            },
            {
                Header: 'Fecha expiración',
                accessor: 'fechaExpiracion',
            }

        ],
        []
    )

    //Retrieve properties from useTable hook of react-table package passing 
    //columns and data variables just created
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    return (

        <div className={className} >
            {
                !hasLoad

                    ?
                    <div style={{ marginTop: "20%" }}>
                        <Spinner />
                    </div>

                    :
                    <>
                        <h5>Anuncios activos</h5>
                        <table {...getTableProps()} className="table">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}

                                    </tr>
                                ))}

                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, index) => {


                                    row.values.id = row.values.id.substring(0, 5)
                                    prepareRow(row)
                                    //console.log(row)
                                    let className = "";
                                    if (selectedAnuncio !== null) {

                                        try {
                                            className = data[index].id === selectedAnuncio.id ? "selected-row " : ""

                                        }
                                        catch{

                                        }


                                    }

                                    return (
                                        <tr {...row.getRowProps()} className={className} onClick={() => {
                                            console.log(data[index].id)
                                            selectAnuncio(data[index].id)
                                        }}>
                                            {row.cells.map(cell => {

                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}

                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </>
                    

            }
        </div>
    )
}


export default TablaAnuncios
