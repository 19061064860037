import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


/**
 * Compnente modal estandar para añadir componentes en el body 
 * @param {*} param0 
 */

const Modal_ = ({ children, className, show, setShow, header, closeText, confirmText, onConfirm}) => {


    const handleClose = () => setShow(false);
    



    return (
        <div className={className} >
           <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{header ? header : null}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {closeText ? closeText : "Cerrar"}
                         </Button>
                        <Button variant="primary" onClick={onConfirm ? onConfirm : handleClose}>
                            {confirmText ? confirmText : "Confirmar"}
                         </Button>
                    </Modal.Footer>
                    
                </Modal>
        </div>
    )
}


export default Modal_
