import styled from 'styled-components'
import FacturasCliente from './FacturasCliente.component'


export default styled(FacturasCliente).attrs()`
   
   .texto-sin-factura{
      position: relative;
      left: 20px
   }

   .contenedor-facturas{
      background-color: white;
    min-height: 500px;
    min-width: 100%;
    
    
   }

   .boton-primera-factura{
      border-radius: 100%;
    height: 80px;
    width: 80px;
    justify-content: center;
    color: #c1c1c1;
    background-color: #e8e8e8;
    
    border: dashed #c1c1c1 3px;
 
    font-size: 2rem;
   }

   .texto-boton{
     
    color: grey;
   }
   .clickable{
      cursor: pointer
   }

   .factura{
      background-color: #efefef;
      
   }

   `