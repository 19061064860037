import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { facturasService } from '../../../../../../../axios'

const Factura = ({ className, factura, cliente }) => {




    const { fechaFactura, numeroFactura, total, subtotal, iva } = factura
    //console.log(cliente)
    //Funcion para obtener la factura llamando al servicio de facturas
    const getFacturaPDF = async () => {


        //construmios el objeto factura
        let factura_ = {
            fechaFactura,
            numeroFactura,
            total,
            subtotal,
            iva,
            conceptos: factura.conceptos,
            cliente: cliente
        }

        try {
            let facturaPDFByteArray = await facturasService.getFactura(factura_)
            //console.log(facturaPDFByteArray)
        
            window.open("http://104.199.59.150:8083/api/v1/facturaspdf", "_blank");

        }
        catch (e) {
            console.log(e)
        }

    }


    return (
        <div className={className} >
            <div className="col-3">
                <div className="row label-fecha align-self-center">
                    <p>Fecha de la factura:</p>
                </div>
                <div className="row fecha align-self-center">
                    <p>{fechaFactura}</p>
                </div>
            </div>
            <div className="col-3">
                <div className="row label-fecha align-self-center">
                    <p>Número de factura:</p>
                </div>
                <div className="row fecha align-self-center">
                    <p>{numeroFactura}</p>
                </div>
            </div>
            <div className="col-2">
                <div className="row label-importe mt-2">
                    <p>Subtotal: </p><strong className="ml-2">{subtotal} €</strong>
                </div>
                <div className="row label-importe ">
                    <p>IVA: </p><strong className="ml-2">{iva} €</strong>
                </div>
                <div className="row label-importe">
                    <p>Total: </p><strong className="ml-2">{total} €</strong>
                </div>
            </div>
            <div className="col-4">
                <button className="btn btn-block btn-outline-primary btn-lg rounded-0 boton-ver"
                    onClick={() => getFacturaPDF()}

                >
                    Ver factura <FontAwesomeIcon icon="file" className="ml-1" /></button>


            </div>
        </div>
    )
}


export default Factura
