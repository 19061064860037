import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const MenuOpcionesFacturas = ({ className, setfilteredFacturas, facturas, seteditingFactura, filteredFacturas }) => {

    //state para almacenar el valor de filtro por numero de factura
    const [filterValue, setfilterValue] = useState(null)

    //state para almacenar los valores de filtro por fecha
    const [filtroFechas, setfiltroFechas] = useState({
        fechaDesde: null,
        fechaHasta: null
    })
    //cada vez que cambia el valor de filtrado, actualizamos las facturas filtradas
    useEffect(() => {
        if (facturas && filterValue !== null) {
            setfilteredFacturas(facturas.filter(factura => (factura.numeroFactura.includes(filterValue))))

        }

    }, [filterValue])

    //cada vez que se selecciona una fecha, se filtran las facturas en el periodo
    useEffect(() => {
        if (facturas && filtroFechas !== null) {

            let facturasFiltradas = filteredFacturas
            if (filtroFechas.fechaDesde !== null) {
                facturasFiltradas = facturas.filter(factura => (new Date(factura.fechaFactura) >= new Date(filtroFechas.fechaDesde)))
            }
            if (filtroFechas.fechaHasta !== null) {
                facturasFiltradas = facturas.filter(factura => (new Date(factura.fechaFactura) <= new Date(filtroFechas.fechaHasta)))
            }
            if(filtroFechas.fechaHasta == null && filtroFechas.fechaDesde == null){
                facturasFiltradas=facturas
            }

            setfilteredFacturas(facturasFiltradas)

        }

    }, [filtroFechas])


    const changeFechas = (e) => {
        setfiltroFechas({
            ...filtroFechas,
            [e.target.name]: e.target.value
        })
    }

    const resetFechas = (prop) => {
        setfiltroFechas({
            ...filtroFechas,
            [prop]: null
        })
    }

    return (
        <div className={className} >
            <div className="row mb-4">
                <div className="col-xl-3 col-4">
                    <label className="filtrar-label d-block"><strong>Búsq. N.Factura:</strong></label>
                    <input type="text" name="filter" className="filter-input " value={filterValue || ""} onChange={(e) => setfilterValue(e.target.value)} />
                    <FontAwesomeIcon icon="search" className="ml-3 search-icon" />
                </div>
                <div className="col-xl-3 col-4">
                    <label className="filtrar-label d-md-block"><strong>Desde:</strong></label>
                    <input type="date" name="fechaDesde" className="date-selector" value={filtroFechas.fechaDesde || ""}
                        onChange={(e) => changeFechas(e)} />
                    <FontAwesomeIcon icon="times" className="ml-3 search-icon" onClick={() => resetFechas("fechaDesde")} />

                </div>
                <div className="col-xl-3 col-4">
                    <label className="filtrar-label d-md-block"><strong>Hasta:</strong></label>
                    <input type="date" name="fechaHasta" className="date-selector" value={filtroFechas.fechaHasta || ""}
                        onChange={(e) => changeFechas(e)} />
                    <FontAwesomeIcon icon="times" className="ml-3 search-icon" onClick={() => resetFechas("fechaHasta")} />

                </div>
                <div className="col-xl-3 col-4">
                    <button className="btn btn-primary boton-nueva-factura" onClick={()=>seteditingFactura(true)}>
                    <FontAwesomeIcon icon="plus" className="mr-3 search-icon"  />
                     Nueva factura
                    </button>
                </div>
            </div>
        </div>
    )
}


export default MenuOpcionesFacturas




