import styled from 'styled-components'
import UpperNavbar from './UpperNavbar.component'


export default styled(UpperNavbar).attrs()`
   

   @media(max-width: 1024px){
         .logo{
         margin-right: 5.2rem;
      }
   }
   .logo{
      margin-right: 13.2rem;
   }

   .navbar {
      background-color: white;
      min-height: 80px;
      color: grey;
      font-family: 'Nunito', sans-serif;
   }
   .navbar-light .navbar-brand {
    color: grey;
   }
   .navbar-light .navbar-nav .nav-link {
      color: grey;
      font-size: 1.2rem;
   }
   a {
      color: grey
   }

   .active{
      color: var(--primary)
   }
   .user-name{
      font-size: 1.3rem;
      position: relative;
      top: 8px;
      right: 23px;
   }

   `

export const Logo = styled.p`

   @media(max-width: 1024px){
      margin-right: 1.1rem
   }

   margin-right: 3.1rem;
   margin-left: 1rem;
   margin-bottom:0px;



`