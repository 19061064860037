import styled from 'styled-components'
import InicioSesion from './InicioSesion.component'


export default styled(InicioSesion).attrs()`
   
   .login{
      margin-top: 15%
   }

   .card{
      border:none
   }
   .card-body{
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding-top: 73px;
      
   }

   

   .logo{
      margin-left: 57px;
      z-index: 999999;
      top: -32%;
      position: absolute;
   }
   .btn{
      border-radius: 0px;
      cursor:pointer
      

   }

   .btn:hover{
      opacity:0.8
   }
   `

   export const ResponsiveImage = styled.img`


   @media (max-width: 1024px){
            margin-left: 20px!important;
            z-index: 999999;
            top: -32%;
            position: absolute;
         }
      margin-left: 57px;
            z-index: 999999;
            top: -32%;
            position: absolute;

     
  
   
   `

   export const ResponsiveCardBody = styled.div`
   

   @media (max-width: 1024px){
         &:before{
            content: ".";
         color: white;
         background-color: #ffffff;
         position: absolute;
         top: -3%;
         left: 6.5%!important;
         width: 310px;
         }
      }

      &:before{
            content: ".";
         color: white;
         background-color: #ffffff;
         position: absolute;
         top: -3%;
         left: 13.5%;
         width: 310px;
         }

    

         border: 1px solid rgba(0, 0, 0, 0.125);
         padding-top: 73px;
         

      
   
   `



