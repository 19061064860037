import styled from 'styled-components'
import ConceptoFacturaInput from './ConceptoFacturaInput.component'


export default styled(ConceptoFacturaInput).attrs()`
   
   #input-fecha{
      max-width: 91%;
   }

   #input-descripcion{
      min-width: 100%;
   }

   #input-unidad{
      max-width: 93%;
   }

   #input-precio-unidad{
      max-width: 109%;
   }
   #input-precio-total{
      max-width: 91%;
   }
   `