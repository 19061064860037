import styled from 'styled-components'
import Factura from './Factura.component'


export default styled(Factura).attrs()`
   
   .fecha{
      position: relative;
    left: 38px;
    font-size: 1.2vw;
    font-weight: bold;
   }

   .label-fecha{
      position: relative;
    top: 15px;
    left: 15px;
    font-size: 1vw;

   }

   .label-importe > p{
      margin-bottom: 0;

   }

   .label-importe{
      font-size: 1vw;
   }
   .boton-ver{
      position: relative;
    top: 1vw;
   }

   
   `