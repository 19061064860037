import React, { useState, useEffect, useContext } from 'react'
import MenuLateral from '../MenuLateral/MenuLateral.container'
import PanelPrincipal from '../PanelPrincipal/PanelPrincipal.container'
import sessionContext from '../../../../context/session/sessionContext'


/**
 * componente que renderiza el contenido segun la secion y la opcion activa
 * Obtiene la seccion de la ruta y la establece en el state
 * @param {string} match  Parametro de ruta para renderizar el contenido de una seccion u otra
 */
const ContentRenderer = ({ className, match }) => {


    const { isLoading, sectionContent, setActiveSection, activeOption } = useContext(sessionContext)
    const [colMenu, setcolMenu] = useState(2)
    const [colPanel, setcolPanel] = useState(12 - colMenu)

    const [isCollapsed, setisCollapsed] = useState(false)

    //

    useEffect(() => {

        if (isCollapsed) {
            setcolMenu(1)
            setcolPanel(11)
        }
        else {
            setcolMenu(2)
            setcolPanel(10)
        }

    }, [isCollapsed])

    useEffect(() => {
        setActiveSection(match.params.section)
    }, [])

    const changeIsCollapse = () => {
        setisCollapsed(!isCollapsed);
    }

    return (
        <div className={className} >
            <div className="container-fluid h-100 p-0">
                <div className="row main-row">
                    {activeOption

                        ?
                        <div className={`col-${colMenu} h-100 menu-lateral shadow`}
                            style={{
                                maxWidth: isCollapsed ? "80px" : "none"
                            }} >

                            <MenuLateral

                                content={sectionContent.options}
                                isCollapsed={isCollapsed}
                                setisCollapsed={setisCollapsed}
                                changeIsCollapse={changeIsCollapse}
                            />
                             </div>
                            :
                            null
                        }

                   
                        <div className={activeOption ?  `col-${colPanel} h-100 main-panel` : `col-11 h-100 main-panel`} >

                            <PanelPrincipal

                                isCollapsed={isCollapsed}
                                setisCollapsed={setisCollapsed}

                            />

                        </div>
                </div>

            </div>
        </div>
    )
}


export default ContentRenderer
