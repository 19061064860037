import styled from 'styled-components'
import NuevoCliente from './NuevoCliente.component'


export default styled(NuevoCliente).attrs()`
   
   .saving-icon{
      position: relative;
    top: -30px;
    left: 102px;
   }

   `