import React, { useState, useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Button, Col, Row } from 'react-bootstrap'
import SavingIcon from '../../../../SavingIcon/SavingIcon.container'
import clientesContext from '../../../../../../context/clientes/clientesContext'
import { FirebaseContext } from '../../../../../../firebase'


const FormularioDetallesCliente = ({ className, cliente }) => {

    const { firebase } = useContext(FirebaseContext)
    const { setClientes } = useContext(clientesContext)

    useEffect(() => {
        seteditedCliente(cliente)
    }, [cliente])

    //estado para almacenar los cambios
    const [editedCliente, seteditedCliente] = useState(cliente)

    //estado para habilitar o deshabilitar la edición
    const [canEdit, setCanEdit] = useState(false)

    //estados para carga y confirmacion de la operacion de guardado 
    const [isSaving, setIsSaving] = useState(false)
    const [docSaved, setdocSaved] = useState(false)


    const handleChange = e => {
        seteditedCliente({
            ...editedCliente,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {

        //logica de modificar el documento en firebase
        setIsSaving(true)


        //Obtener el documento cliente por id y lo actualiza
        const updated = await firebase.db.collection("clientes").doc(cliente.id).update(editedCliente)

        //Actualizamos los clientes en el state 

        const obtenerClientes = async () => {

            await firebase.db.collection("clientes").orderBy("fechaAlta", "desc")
                .onSnapshot(handleSnapshot);

        }

        obtenerClientes()
        
        setIsSaving(false)
        setdocSaved(true)
        setCanEdit(false)
        //funcion para procesar el resultadod e la consulta a firestore
        function handleSnapshot(snapshot) {

            const clientes = snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })

            setClientes(clientes)
            

        }
    }

    return (
        <div className={className} >
            
                <Form>
                    <Form.Group controlId="nombre">
                        <Form.Label>Nombre:</Form.Label>
                        <Form.Control
                            type="text"
                            name="nombre"
                            value={editedCliente.nombre ? editedCliente.nombre : ""}
                            placeholder="Introduce el nombre dle cliente..."
                            onChange={(e) => handleChange(e)}
                            readOnly={!canEdit ? true : false}
                        />
                    </Form.Group>

                    <Form.Group controlId="cif">
                        <Form.Label>CIF</Form.Label>
                        <Form.Control
                            type="text"
                            name="cif"
                            value={editedCliente.cif ? editedCliente.cif : ""}
                            placeholder="Introduce el cif "
                            onChange={(e) => handleChange(e)}
                            readOnly={!canEdit ? true : false}
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group controlId="direccion">
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="direccion"
                                    value={editedCliente.direccion ? editedCliente.direccion : ""}
                                    placeholder="Introduce la dirección del cliente"
                                    onChange={(e) => handleChange(e)}
                                    readOnly={!canEdit ? true : false}

                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="ciudad">
                                <Form.Label>Ciudad</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ciudad"
                                    value={editedCliente.ciudad ? editedCliente.ciudad : ""}
                                    placeholder="Introduce la dirección del cliente"
                                    onChange={(e) => handleChange(e)}
                                    readOnly={!canEdit ? true : false}
                                />

                            </Form.Group>
                        </Col>
                    </Row>
                    <Button
                        variant="primary"

                        onClick={
                            canEdit

                                ?
                                () => handleSubmit()
                                :
                                () =>{
                                    setdocSaved(false)
                                    setCanEdit(true)
                                    
                                }

                        } 
                        disabled={isSaving ? true : false}>
                        {
                            canEdit ? "Guardar cambios" : "Editar"
                        }
                    </Button>
                    {
                        canEdit && !isSaving

                            ?
                            <FontAwesomeIcon
                                icon={"times"}
                                className="cancel-edit-icon"
                                onClick={() => {
                                    seteditedCliente(cliente)
                                    setCanEdit(false)
                                }}
                            />
                            :
                            null


                    }
                    
                    <SavingIcon className="saving-icon"
                        isSaving={isSaving}
                        saved={docSaved}
                    />
                </Form>
            
        </div>
    )
}


export default FormularioDetallesCliente
