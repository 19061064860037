import app from 'firebase/app';
import 'firebase/auth'
import firebaseConfig from './config';
import 'firebase/firestore'
import 'firebase/storage'
//Se crea la clase para agregar todos los metodos de firebase en un solo objeto
class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(firebaseConfig)

        }
        this.auth = app.auth()
        this.db = app.firestore()
        this.storage = app.storage()


    }

    //Registrar usuarios
    async registrarUsuario(nombre, email, password) {
        const nuevoUsuario = await this.auth.createUserWithEmailAndPassword(email, password);

        return await nuevoUsuario.user.updateProfile({
            displayName: nombre
        })
    }

    //Inicio sesion de usuario
    async login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password)
    }

    //Cerrar sesion de usuario
    async cerrarSesion() {
        await this.auth.signOut();
    }

    //Obtener archivos pdf de storage
    async getHorarioPDF(nombre_archivo) {

        return await this.storage.ref(`pdf/${nombre_archivo}.pdf`).getDownloadURL()
    }

    //Subir un archivo
    async uploadFile(archivo, nombre_archivo) {
        const storageRef = this.storage.ref();

        // Create a reference to the file
        let fileRef = storageRef.child(`pdf/${nombre_archivo}.pdf`);
        // SUbir archivo como Blob o File
       let uploadTask = fileRef.put(archivo)
        //Devuelve el objeto de carga de archivo para controlar el progreso
       return uploadTask
    }

    //Metodos de clientes

    
}

const firebase = new Firebase();
export default firebase;

