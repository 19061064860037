import styled from 'styled-components'
import ContentRenderer from './ContentRenderer.component'


export default styled(ContentRenderer).attrs()`

      .main-row{
         height: 100vh;
         background-color: #f3f3f3;
      }

      .menu-lateral{
         background-color: #f9f9f9;
         transition: max-width 0.1s ease;
      }

      .col-2 {
         flex-grow: 1;
         transition: all 150ms ease;
      }

      .col-1 {
         width: 0; 
         flex-shrink: 1;
         transition: all 150ms ease;
      }
      .main-panel{
         background-color: #f3f3f3;
         transition: max-width 0.1s ease;
         overflow-y: scroll;
         
      }

      ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
         /* Optional: show position indicator in red */
         ::-webkit-scrollbar-thumb {
            background: #FF0000;
         }

   `