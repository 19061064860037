import React from 'react';
import './index.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import firebase, { FirebaseContext } from './firebase'
import AppContainer from './AppContainer'
import SessionState from './context/session/sessionState'
import './custom.scss';
import useAutenticacion from './hooks/useAutenticacion'
import history from './history'
//Import fontawesome and create icons library
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBullhorn, faClock, faAngleLeft, faAngleRight, faSpinner, faCheck, faSearch, faUserPlus, faTimes, faPlus, faSave, faFile  } from '@fortawesome/free-solid-svg-icons'
//import {  } from '@fortawesome/free-brands-svg-icons'
library.add(faBullhorn, faClock, faAngleLeft, faAngleRight, faSpinner, faCheck, faSearch, faUserPlus, faTimes, faPlus, faSave, faFile)



function App() {

  const usuario = useAutenticacion()

  return (

    <div className="App container-fluid  p-0">
      <FirebaseContext.Provider
        value={{
          firebase,
          usuario
        }}
      >
        <SessionState>
          <Router history={history}>
            <AppContainer className="container-fluid" />
          </Router>
        </SessionState>
      </FirebaseContext.Provider>
    </div>
  );
}

export default App;
