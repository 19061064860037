import styled from 'styled-components'
import MenuOpcionesFacturas from './MenuOpcionesFacturas.component'


export default styled(MenuOpcionesFacturas).attrs()`
   
   .filter-input{
      max-width:7rem
   }
   .boton-nueva-factura{
      position: relative;
      top: 1vw;
   }

   .date-selector{
      max-width: 9rem
   }

   `





