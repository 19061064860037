import styled from 'styled-components'
import UpperNavbarLink from './UpperNavbarLink.component'


export default styled(UpperNavbarLink).attrs()`
   
   .active{
      color: var(--primary)!important;
   }

   `