import React from 'react'
import PropTypes from 'prop-types'


const Spinner = ({
  className
}) => (
  <div className={className}>
    <div className="loader">Loading...</div>
  </div>
);

export default Spinner

Spinner.propTypes = {
  className: PropTypes.string.isRequired,
}