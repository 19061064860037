import React, { useContext, useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import clientesContext from '../../../../../../context/clientes/clientesContext'
import { FirebaseContext } from '../../../../../../firebase'
import Spinner from '../../../../Spinner/Spinner.container'
import Factura from './Factura/Factura.container'
import MenuOpcionesFacturas from './MenuOpcionesFacturas/MenuOpcionesFacturas.container'
import {
    Link

} from "react-router-dom";


const FacturasCliente = ({ className, cliente, seteditingFactura }) => {

    const { firebase } = useContext(FirebaseContext)
    //estado  para loader
    const [hasLoad, sethasLoad] = useState(false)
    //contexto de clientes
    const { setFacturasCliente, facturasCliente } = useContext(clientesContext)


    //estado para almacenar las facturas filtradas 
    const [filteredFacturas, setfilteredFacturas] = useState(null)

    //carga de las facturas del cliente seleccionado
    useEffect(() => {


        const obtenerFacturas = async () => {

            await firebase.db.collection("facturas").where("idCliente", "==", cliente.id).orderBy("fechaFactura", "desc")
                .onSnapshot(handleSnapshot);

        }

        obtenerFacturas()


    }, [cliente])

    //funcion para procesar el resultadod e la consulta a firestore
    function handleSnapshot(snapshot) {

        const facturas = snapshot.docs.map(doc => {
            return {
                id: doc.id,
                ...doc.data()
            }
        })
        //console.log(anuncios)
        //set anuncios activos en el state
        setFacturasCliente(facturas)
        setfilteredFacturas(facturas)
        sethasLoad(true)

    }


    return (
        <div className={className} >
            {
                !hasLoad

                    ?
                    <div style={{ marginTop: "20%" }}>
                        <Spinner />
                    </div>
                    :
                    <div className="contenedor-facturas  row justify-content-center" >
                        {
                            facturasCliente.length > 0

                                ?
                                <div className="col-12 p-4">
                                <MenuOpcionesFacturas
                                    setfilteredFacturas={setfilteredFacturas}
                                    facturas={facturasCliente}
                                    seteditingFactura={seteditingFactura}
                                    filteredFacturas={filteredFacturas}

                                />
                                {
                                    filteredFacturas.length > 0 

                                    ?

                                    filteredFacturas.map((factura, index) => 
                                        <Factura 
                                            factura={factura} 
                                            key={index} 
                                            cliente={cliente}
                                            className="row factura mb-3"
                                            />
                                    )

                                    :
                                    <i>Sin facturas con los filtros seleccionados</i>
                                }
                                </div>

                                :

                                <div className="col-10 clickable" onClick={() => seteditingFactura(true)} style={{ paddingTop: "6rem" }}>
                                    <div className="row justify-content-center">
                                        <p className="texto-boton">Añadir primera factura</p>
                                    </div>
                                    <div className="row justify-content-center">
                                    
                                            <button className=" btn  btn-light boton-primera-factura">
                                                <FontAwesomeIcon icon="plus" />

                                            </button>

                                    </div>
                                </div>
                        }
                    </div>
            }
        </div>
    )
}


export default FacturasCliente
