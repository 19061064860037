import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    BrowserRouter as Router,
    Switch,
    Route,

} from "react-router-dom";
import sessionContext from '../../../../context/session/sessionContext'
import Spinner from '../../Spinner/Spinner.container'
import Anuncios from '../../GestionWeb/Anuncios/Anuncios.container'
import Horarios from '../../GestionWeb/Horarios/Horarios.container'
import Inicio from '../../Inicio/Inicio.container'
import history from '../../../../history'
import Explorar from '../../Clientes/Explorar/Explorar.container'
import NuevoCliente from '../../Clientes/NuevoCliente/NuevoCliente.container';
import EditorFacturas from '../../Clientes/Explorar/DetalleCliente/EditorFacturas/EditorFacturas.container';
const componentDictionary = {
    gestionWeb:{
        anuncios: Anuncios,
        horarios: Horarios
    },
    inicio: Inicio,
    clientes: {
        explorar: Explorar,
        
        nuevoCliente: NuevoCliente
    }
}

const PanelPrincipal = ({ isCollapsed, className }) => {

    const { activeSection, activeOption, isLoadingOption } = useContext(sessionContext)



    return (
        <div className={className} >
            {
                isLoadingOption

                    ?
                    <div style={{ marginTop: "20%" }}>
                        
                    </div>
                    :
                    <Router history={history}>
                       
                            <Switch>
                            
                            {
                                activeOption === null
                                
                                ? 
                                <Route path={`/${activeSection}`} component={componentDictionary[activeSection]} />
                                :
                                <Route path={`/${activeSection}/${activeOption}`} component={componentDictionary[activeSection][activeOption]} />

                            }
                            </Switch>
                    
                    </Router>
            }




        </div>
    )
}


export default PanelPrincipal
//                                <Route exact path={`/${activeSection}/${activeOption}`} component={componentDictionary[activeSection][activeOption]} />
