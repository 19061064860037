import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Componente que mnuestra un icno de carga o confirmacion para un proceso de guardado
 * @param {Boolean} isSaving Define si hay un proceso de carga activo 
 * @param {Boolean} saved Define si se ha guardado un elemento
 */
const SavingIcon = ({ className, isSaving, saved }) => {


    const [iconClass, seticonClass] = useState(null)
    const [styleClass, setstyleClass] = useState("")
    useEffect(() => {

        function getIconClass() {

            let icon;
            let style

            if (isSaving) {
                icon = "spinner"
                style = "spinner"
            }
            else {
                if (saved) {
                    icon = "check"
                    style = "check"
                }
                else {
                    icon = "check"
                    style = "none"
                }
            }

            return [icon, style]
        }

        seticonClass(getIconClass()[0])
        setstyleClass(getIconClass()[1])
    }, [isSaving, saved])
    return (
        <div className={className} >
            {
                iconClass == null

                    ?
                    null
                    :


                    <FontAwesomeIcon icon={iconClass} className={styleClass}

                    />

            }
        </div>
    )
}


export default SavingIcon
