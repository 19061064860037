import React, { useState , useEffect}  from 'react'

const useValidacion = (stateInicial, validar, callback) => {

    const [valores, setValores] = useState(stateInicial)
    const [errores, setErrores] = useState({})
    const [submitForm, setsubmitForm] = useState(false);


    useEffect(() => {
       
        if(submitForm){
            const noErrores = Object.keys(errores).length === 0;
            if(noErrores){
                callback()
            }

            setsubmitForm(false)
        }

    }, [errores])

    //funcion que se ejecuta cuando el usuario escibe
    const handleChange = e => {

        setValores({ 
            ...valores,
            [e.target.name] : e.target.value
        })
    }    
    const handleSubmit = e =>{

        e.preventDefault();
        const erroresValidacion  = validar(valores)
        setErrores(erroresValidacion)
        setsubmitForm(true)

    }

    return {
        valores, 
        errores, 
        submitForm,
        handleSubmit,
        handleChange
    }
        
    
}

export default useValidacion
